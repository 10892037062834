import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private _token: string;

  get token(): string {
    return this._token;
  }

  set token(value: string) {
    this._token = value;
    // setting local storage data
    window.localStorage.setItem("currentUser", JSON.stringify(value["token"]));
    window.localStorage.setItem("group", JSON.stringify(value["group"]));
    window.localStorage.setItem("name", JSON.stringify(value["name"]));
    window.localStorage.setItem("id", JSON.stringify(value["id"]));
    window.localStorage.setItem("userData", JSON.stringify(value));
    window.localStorage.setItem("role", JSON.stringify(value["role"]["name"]));
    window.localStorage.setItem("session", JSON.stringify(value["session"]));
    window.localStorage.setItem(
      "organization-modules",
      JSON.stringify(value["role"]["modules"])
    );
    window.localStorage.setItem(
      "redirection-modules",
      JSON.stringify(value["organization_modules"]["value"])
    );
  }
}
