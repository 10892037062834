import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  ActivatedRoute,
} from "@angular/router";

@Injectable({ providedIn: "root" })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private route: ActivatedRoute) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let organizationModule;
    if (localStorage.getItem("redirection-modules")) {
      organizationModule = JSON.parse(
        localStorage.getItem("redirection-modules")
      );
    }
    else {
      organizationModule = JSON.parse(
        localStorage.getItem("organization-modules")
      );
    }

    console.log(organizationModule)

    let role = JSON.parse(localStorage.getItem("role"));
    if (role == "Admin") {
      if (
        localStorage.getItem("currentUser") &&
        (organizationModule.indexOf("Sales") != -1 || organizationModule.indexOf("sales") != -1)
      ) {
        return true;
      } else if (
        localStorage.getItem("currentUser") &&
        (organizationModule.indexOf("Marketing") != -1 || organizationModule.indexOf("marketing") != -1)
      ) {
        this.router.navigate(["/marketing"]);
        return false;
      } else if (
        localStorage.getItem("currentUser") &&
        (organizationModule.indexOf("Service") != -1 || organizationModule.indexOf("service") != -1)
      ) {
        this.router.navigate(["/services"]);
        return false;
      } else {
        if (route.routeConfig.path == "verification") {
          this.router.navigateByUrl("/login");
        } else {
          this.router.navigateByUrl("/login");
        }
        return false;
      }
    } else {
      if (
        (localStorage.getItem("currentUser") && role == "Sales Person") ||
        role == "Sales Manager" ||
        role == "Sales Agent"
      ) {
        return true;
      } else if (
        (localStorage.getItem("currentUser") && role == "Marketing Manager") ||
        role == "Marketer"
      ) {
        this.router.navigate(["/marketing"]);
        return false;
      } else if (
        (localStorage.getItem("currentUser") && role == "Service Admin") ||
        role == "Service manager" ||
        role == "Service Associate"
      ) {
        this.router.navigate(["/services"]);
        return false;
      } else {
        if (route.routeConfig.path == "verification") {
          this.router.navigateByUrl("/login");
        } else {
          this.router.navigateByUrl("/login");
        }
        return false;
      }
    }
  }
}
