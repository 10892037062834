import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";

@Component({
  selector: "app-thank-youfor-response",
  templateUrl: "./thank-youfor-response.component.html",
  styleUrls: ["./thank-youfor-response.component.scss"],
})
export class ThankYouforResponseComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<ThankYouforResponseComponent>) {}

  ngOnInit() {}
  closeDialog() {
    this.dialogRef.close(true);
  }
}
