import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadService } from 'src/app/core/services/lead/lead.service';
import { ProductService } from 'src/app/core/services/product/product.service';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-buy-credit-form',
  templateUrl: './buy-credit-form.component.html',
  styleUrls: ['./buy-credit-form.component.scss']
})
export class BuyCreditFormComponent implements OnInit {
  state;

  emailCredits= 0;
  smsCredits= 0;
  validationCredits= 0;
  whatsappCredits= 0;

  ecredits;
  scredits;
  vcredits;
  wcredits;

  price= {
    calcEmailPrice: 0,
    calcSMSPrice: 0,
    calcValidatonPrice: 0,
    calcWhatsappPrice: 0,
  }

  showPromo: boolean= false;
  promoCode: string= null;
  promoErrMsg= '';
  promoDiscountAmount= 0;
  promoData= null;
  promoBtnDisable= false;
  promoLoadMsg= '';

  totalPrice= 0;
  subTotal= 0;

  addedCredits= {
    email: {
      name: 'Email Credits',
      credits: 0,
      price: 0
    },
    sms: {
      name: 'SMS Credits',
      credits: 0,
      price: 0
    },
    validation: {
      name: 'Verification Credits',
      credits: 0,
      price: 0
    },
    whatsapp: {
      name: 'Whatsapp Credits',
      credits: 0,
      price: 0
    }
  };

  emailCreditData= [
    {
      credits: 10000,
      price: 10000*0.15
    },
    {
      credits: 25000,
      price: 25000*0.1
    },
    {
      credits: 50000,
      price: 50000*0.066
    },
    {
      credits: 100000,
      price: 100000*0.05
    },
    {
      credits: 350000,
      price: 350000*0.04
    },
    {
      credits: 500000,
      price: 500000*0.035
    },
    {
      credits: 1000000,
      price: 1000000*0.032
    },

  ];

  smsCreditData= [
    {
      credits: 10000,
      price: 1400
    },
    {
      credits: 25000,
      price: 3500
    },
    {
      credits: 50000,
      price: 6500
    },
    {
      credits: 100000,
      price: 13000
    },
    {
      credits: 350000,
      price: 42000
    },
    {
      credits: 500000,
      price: 60000
    },
    {
      credits: 1000000,
      price: 110000
    }
  ];

  
  validationCreditData= [
    {
      credits: 25000,
      price: 25000*0.15
    },
    {
      credits: 50000,
      price: 50000*0.12
    },
    {
      credits: 100000,
      price: 100000*0.1
    },
    {
      credits: 500000,
      price: 500000*0.07
    },
    {
      credits: 1000000,
      price: 1000000*0.05
    },
   
  ];
  
  whatsappCreditData= [
    {
      credits: 1000,
      price: 1499
    },
    {
      credits: 2500,
      price: 2499
    },
    {
      credits: 3000,
      price: 3299
    },
    {
      credits: 4000,
      price: 4979
    }
  ];
  constructor(
    private dialogRef: MatDialogRef<BuyCreditFormComponent>,
    private userService: UserService,
    private productService: ProductService,
    private leasService: LeadService,
    @Inject(MAT_DIALOG_DATA) public data,
    ) { }

  ngOnInit() {
    this.state= this.data.state;
    this.onChange('email',this.emailCreditData[0].credits)
    this.emailCredits= this.emailCreditData[0].credits;
    this.onChange('sms',this.smsCreditData[0].credits)
    this.smsCredits= this.smsCreditData[0].credits;
    this.onChange('validation',this.validationCreditData[0].credits)
    this.validationCredits= this.validationCreditData[0].credits;
    this.onChange('whatsapp',this.whatsappCreditData[0].credits)
    this.whatsappCredits= this.whatsappCreditData[0].credits;
  
    this.getBillingAddress()

  }


  selectEmailCredit(credit){
    this.price.calcEmailPrice= credit.price;
    this.ecredits= credit;
    this.emailCredits= credit.credits
    // if(this.ecredits.credits==10000 || this.ecredits.credits==50000 || this.ecredits.credits==100000 ){
    //   this.price.calcEmailPrice-=1;
    // }
  }
  selectSmsCredit(credit){
    this.price.calcSMSPrice= credit.price;
    this.scredits= credit;
    this.smsCredits= credit.credits
  }
  selectValidationCredit(credit){
    this.price.calcValidatonPrice= credit.price;
    this.vcredits= credit;
    this.validationCredits= credit.credits
  }
  selectWhatsappCredit(credit){
    this.price.calcWhatsappPrice= credit.price;
    this.wcredits= credit;
    this.whatsappCredits= credit.credits
  }

  onChange(val,event){
    switch(val){
      case 'email':
        this.ecredits= this.findEmailCredit(event)
        this.price.calcEmailPrice= event*(this.ecredits.price/this.ecredits.credits)
        console.log(this.ecredits.credits)
        // if(this.ecredits.credits==10000 || this.ecredits.credits==50000 || this.ecredits.credits==100000 ){
        //   this.price.calcEmailPrice-=1;
        // }
        break
      case 'sms':
        this.scredits= this.findSmsCredit(event)
        this.price.calcSMSPrice= event*(this.scredits.price/this.scredits.credits)
        break
      case 'validation':
        this.vcredits= this.findValidationCredit(event)
        this.price.calcValidatonPrice= event*(this.vcredits.price/this.vcredits.credits)
        break
      case 'whatsapp':
        this.wcredits= this.findWhatsappCredit(event)
        this.price.calcWhatsappPrice= event*(this.wcredits.price/this.wcredits.credits)
        break
    }
  }

  findEmailCredit(value){
    var max=0;
    var c;
    for(let credit of this.emailCreditData){
      if(credit.credits<=value && credit.credits>max){
        c= credit;
      }
    }
    return c;
  }
  findSmsCredit(value){
    var max=0;
    var c;
    for(let credit of this.smsCreditData){
      if(credit.credits<=value && credit.credits>max){
        c= credit;
      }
    }
    return c;
  }
  findValidationCredit(value){
    var max=0;
    var c;
    for(let credit of this.validationCreditData){
      if(credit.credits<=value && credit.credits>max){
        c= credit;
      }
    }
    return c;
  }
  findWhatsappCredit(value){
    var max=0;
    var c;
    for(let credit of this.whatsappCreditData){
      if(credit.credits<=value && credit.credits>max){
        c= credit;
      }
    }
    return c;
  }


  showGST: boolean = false
  gstNumber = ''
  
  AddGst() {


    console.log(this.billingData)
    console.log(this.gstNumber)
    
    let sendData= {
      key: 'billing_address',
      value: {data: {...this.billingData, gst: this.gstNumber}}
    }
    console.log(sendData)
    console.log(this.billAddId)

    
    this.productService.editConstant(this.billAddId, sendData)
    .subscribe((data: any)=>{
      console.log(data)
      // this.billingData.gst = data?.billing_address?.data?.gst
      this.getBillingAddress();
      this.showGST = false
    })
  
  }

  billingData
  billAddId

  
  getBillingAddress(){
    this.productService.getConstant().subscribe(data=>{
    


      // for (const element of data) {
      //   console.log(element);
      
      //   if (element.key == "billing_address" && typeof element.value === 'object') {
      //     console.log("Billing address found with data:", element);
          
      //     this.billAddId = element._id;
      //     this.billingData = element?.value;
      
      //     console.log(this.billAddId);
      //     console.log(this.billingData);
          
      //     break;
      
      //   } else if (element.key == "address" && element?.value?.data) {
      //     console.log("Address found:", element);
      
      //     this.billAddId = element._id;
      //     this.billingData = element?.value?.data;
          
      //     break;
      //   }
      // }
    
      data.forEach((element) => {
        console.log(element)
        
        if (element.key == "billing_address" ) {
           console.log(element)
           
           this.billAddId = element._id

           if ( element?.value?.data) {
            
             this.billingData = element?.value?.data
            }

          console.log(this.billAddId)
          console.log(this.billingData)
        } else if(element.key == "address") {
          console.log(element)
          
          this.billAddId = element._id
          
          this.billingData = element?.value?.data
          console.log(this.billingData)


        }
      })
    })
  }


  getGst(percent){
    this.subTotal= this.getSum() - this.promoDiscountAmount;
    let gst= (this.subTotal*percent)/100
    this.totalPrice= this.subTotal + gst;
    return gst;
  }

  getSum(){
    return this.addedCredits.email.price + this.addedCredits.sms.price + this.addedCredits.validation.price + this.addedCredits.whatsapp.price
  }
  showWindow: boolean = false
  addCredit(val){

    this.showWindow = true;

    switch(val){
      case 'email':
        this.addedCredits.email.credits= this.emailCredits;
        this.addedCredits.email.price= this.price.calcEmailPrice;
        break
      case 'sms':
        this.addedCredits.sms.credits= this.smsCredits;
        this.addedCredits.sms.price= this.price.calcSMSPrice;
        break
      case 'validation':
        this.addedCredits.validation.credits= this.validationCredits;
        this.addedCredits.validation.price= this.price.calcValidatonPrice;
        break
      case 'whatsapp':
        this.addedCredits.whatsapp.credits= this.whatsappCredits;
        this.addedCredits.whatsapp.price= this.price.calcWhatsappPrice;
        break
    }

    this.calculatePromoAmount(this.promoData)
  }


  buy(){
    if(this.promoData)
      this.dialogRef.close({data: this.addedCredits, total: this.totalPrice, promo: this.promoData._id});
    else  
      this.dialogRef.close({data: this.addedCredits, total: this.totalPrice, promo: 0});
  }

  checkPromo(){
    this.promoErrMsg= "";
    this.promoLoadMsg= 'Checking...'
    this.userService.getPromoCodeData(this.promoCode)
      .subscribe(data=>{
        this.promoData= data['data']
        this.calculatePromoAmount(data['data'])
        if(data['status']!=200){
          this.promoErrMsg= 'Invalid Promocode'
        }
        this.promoLoadMsg= ''
    // this.leadService.stopLoading()
      })
  }

  calculatePromoAmount(data){
    if(data){
      // amount
      if(data.amount!=0){         // for discount not on percentage
        if(this.subTotal<data.minAmount){
          this.promoErrMsg= 'Promocode apply only on order above INR ' + data.minAmount;
          return
        }
        this.promoDiscountAmount= data.amount;
      }

      // percentage
      if(data.percentage!=0){         // for discount not on percentage
        if(this.subTotal<data.minAmount){
          this.promoErrMsg= 'Promocode apply only on order above INR ' + data.minAmount;
          return
        }
        this.promoDiscountAmount= (this.getSum()/100)*data.percentage;
        if(this.promoDiscountAmount>data.maxAmount){
          this.promoDiscountAmount= data.maxAmount
        }
      }
    }
  }
  checkValidPromo(){
    this.promoCode= this.promoCode.toUpperCase()
    if(this.promoCode.includes(' ')){
      this.promoBtnDisable= true;
      this.promoErrMsg= "Invalid Promocode"
    }else{
      this.promoErrMsg= '';
      this.promoBtnDisable= false;
    }
  }

  public remove(value){
    this.showWindow = false
    switch(value){
      case 'email':
        this.addedCredits.email.credits=0;
        this.addedCredits.email.price= 0;
        break
      case 'sms':
        this.addedCredits.sms.credits=0;
        this.addedCredits.sms.price= 0;
        break
      case 'validation':
        this.addedCredits.validation.credits=0;
        this.addedCredits.validation.price= 0;
        break
      case 'whatsapp':
        this.addedCredits.whatsapp.credits=0;
        this.addedCredits.whatsapp.price= 0;
        break
      case 'promo':
        this.promoData= null;
        this.promoErrMsg= '';
        this.promoDiscountAmount= 0;
        break
    }
  }

  closeModel(){
    this.dialogRef.close()
  }

}
