import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

import { Router } from '@angular/router';
import { SnackbarService } from 'src/app/core/utils/snackbar/snackbar.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatOptionSelectionChange } from '@angular/material';
import { UserService } from 'src/app/core/services/user/user.service';
import { EditCompanyComponent } from 'src/app/views/dashboard/company/edit-company/edit-company.component';
import { Observable, of } from 'rxjs';
import * as country from 'country-state-city';
import { pickBy, identity } from 'lodash';

export function patternValidator(regexp: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (value === '') {
      return null;
    }
    return !regexp.test(value) ? { 'patternInvalid': { regexp } } : null;
  };
}

export interface DialogData {
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  organization = localStorage.getItem('organization');
  @ViewChild('resetForm') resetForm;
  allRoles;
  isAdmin;
  signupForm;
  alluserGroups;
  countries: Observable<any>;
  states: Observable<any>;
  cities: Observable<any>;
  countryId;
  stateId;
  isAgent;
  agentData;
  role;
  editData;
  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private snackBar: SnackbarService,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<UserEditComponent>,
  ) { 
    this.editData = this.data;
    let user = JSON.parse(localStorage.getItem('userData'));
    this.role = user.role;
    if(this.role.name == 'Admin'){
      if(this.editData.role.name != 'Admin'){
        console.log('yes');
        this.isAdmin = true;
      }
      else{
        console.log('no')
        if(user.id == this.editData._id){
          this.isAdmin = true;
        }
        else{
          this.isAdmin = false;
        }
      }
    }
  }

  ngOnInit() {
    this.getCountries();
    if(this.data['for'] == 'sales'){
      this.getRoles({modules:'sales'});
    }
    else{
      this.getRoles({modules:'marketing'});

    }
    if (this.data['ca']) {
      this.isAgent = true;
      console.log(this.data['ca'])
      this.agentData = this.data['ca'];
    }
    this.createForm();
  }

  createForm(): void {
    this.signupForm = this.fb.group({
      name: [this.data['name'] ? this.data['name'] : ''],
      email: [this.data['email'] ? this.data['email'] : '', [patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: [''],
      phone: [this.data['phone'] ? this.data['phone'] : ''],
      // group:[''],
      role: [this.data['role'] ? this.data['role']['_id'] : ''],
      address: this.fb.group({
        lineOne: [this.agentData ? this.agentData['address'] ? this.agentData['address']['lineOne']?this.agentData['address']['lineOne']:'' : '' : ''],
        lineTwo: [this.agentData ? this.agentData['address'] ? this.agentData['address']['lineTwo']?this.agentData['address']['lineTwo']:'' : '' : ''],
        area: [this.agentData ? this.agentData['address'] ? this.agentData['address']['area']?this.agentData['address']['area']:'' : '' : ''],
        city: [this.agentData ? this.agentData['address'] ? this.agentData['address']['city']?this.agentData['address']['city']:'' : '' : ''],
        state: [this.agentData ? this.agentData['address'] ? this.agentData['address']['state']?this.agentData['address']['state']:'' : '' : ''],
        country: [this.agentData ? this.agentData['address'] ? this.agentData['address']['country'] : 'India' : ''],
        pin: [this.agentData ? this.agentData['address'] ? this.agentData['address']['pin']?this.agentData['address']['pin']:'' : '' : ''],
      })
    });
  }

  editUser(data) {
    let agentData = { ...data };
    data.address = '';
    this.userService.editUser(this.data['_id'], pickBy(data, identity), this.dialogRef);
    if (this.isAgent) {
      this.editAgent(agentData);
    }
  }

  editAgent(data) {
    console.log(data);
    this.userService.editAgent(this.agentData['_id'], data).subscribe(res => {

    })
  }

  getRoles(params) {
    this.auth.getRoles(params).subscribe(
      (res) => {
        console.log(res)
        this.allRoles = res['data'];
      }
    )
  }

  // getting location data
  public async getCountryId(event: MatOptionSelectionChange, id) {
    console.log(this.countryId);
    if (event.source.selected) {
      this.countryId = await id;
    }
  }
  public async getStateId(event: MatOptionSelectionChange, id) {
    console.log(this.states);
    if (event.source.selected) {
      this.stateId = await id;
    }
  }
  public getCountries() {
    this.countries = of(country.getAllCountries());
  }

  ngDoCheck(): void {
    this.states = of(country.getStatesOfCountry(this.countryId));
    this.cities = of(country.getCitiesOfState(this.stateId));
  }

}
