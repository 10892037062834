import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { pickBy, identity } from "lodash";
import { Observable, of } from "rxjs";
import {
  map,
  retry,
  catchError,
  concatMap,
  finalize,
  shareReplay,
  tap,
  takeLast,
} from "rxjs/operators";
import { ResponseService } from "../../utils/response/response.service";
import { SnackbarService } from "../../utils/snackbar/snackbar.service";
import { Router } from "@angular/router";
import { LoaderService } from "../../utils/loader/loader.service";
import { Subject } from "rxjs";
import { LeadService } from "../lead/lead.service";

@Injectable({
  providedIn: "root",
})
export class ProductService {
  session = JSON.parse(localStorage.getItem("session"));
  constructor(
    private http: HttpClient,
    private snackBar: SnackbarService,
    private response: ResponseService,
    private loader: LoaderService,
    private router: Router,
    private leadService: LeadService
  ) { }

  // categories
  public addCategory(data) {
    this.leadService.startLoading();
    data.session = this.session;
    return this.http.post(`${environment.API_URL}/category/add`, data).pipe(
      map((res) => res),
      tap(
        (res) => this.snackBar.openSnackBar(res["message"]),
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public getCategories() {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/category/list/all`, {
        params: { session: this.session },
      })
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public deleteCategory(categoryId) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/category/${categoryId}/delete`)
      .pipe(
        map(this.response.extractData),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }


  public assignMultipleSalesPerson(data, prodId) {
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/product/${prodId}/assign-users`, data).pipe(
      map((res) => res),
      tap(
        (res) => this.snackBar.openSnackBar(res["message"]),
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.leadService.stopLoading())
    );

  }

  public editCategory(categoryId, data) {
    this.leadService.startLoading();
    return this.http
      .put(`${environment.API_URL}/category/${categoryId}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // subcategories
  public addSubcategory(data) {
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/subCategory/add`, data).pipe(
      map((res) => res),
      tap(
        (res) => this.snackBar.openSnackBar(res["message"]),
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public editSubcategory(id, data) {
    this.leadService.startLoading();
    return this.http
      .put(`${environment.API_URL}/subCategory/${id}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public deleteSubcategory(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/subCategory/${id}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public getSubcategories(categoryId) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/subCategory/${categoryId}/list/all`)
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public subcategoryList() {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/subCategory/list/all`, {
        params: { session: this.session },
      })
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }
  // measurements
  public addMeasurement(data) {
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/measurement/add`, data).pipe(
      map((res) => res),
      tap(
        (res) => this.snackBar.openSnackBar(res["message"]),
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public getMeasurements() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/measurement/list/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public deleteMeasurement(measurementId) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/measurement/${measurementId}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public editMeasurement(measurementId, data) {
    this.leadService.startLoading();
    return this.http
      .put(`${environment.API_URL}/measurement/${measurementId}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // products
  public addProduct(data) {
    this.leadService.startLoading();
    data.session = this.session;
    return this.http.post(`${environment.API_URL}/product/add`, data).pipe(
      map((res) => res),
      tap(
        (res) => {
          this.snackBar.openSnackBar(res["message"]);
          this.router.navigate(["/settings/products/manage-product"]);
        },
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public getProductsSubcategory(id) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/product/${id}/list/all`, {
        params: { session: this.session },
      })
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // apply filter
  public applyFilters(param) {
    this.leadService.startLoading();
    let newParam = param ? param : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/lead/list/filtered`, {
        params: pickBy(newParam, identity),
      })
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public deleteProduct(productId) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/product/${productId}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public editProduct(productId, data) {
    this.leadService.startLoading();
    return this.http
      .put(`${environment.API_URL}/product/${productId}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // discount

  public addDiscount(data) {
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/discount/create`, data).pipe(
      map((res) => res),
      tap(
        (res) => {
          this.snackBar.openSnackBar(res["message"]);
          this.router.navigate(["/settings/discounts/discount-manage"]);
        },
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public editDiscount(id, data) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/discount/${id}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public deleteDiscount(id, dialog) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/discount/${id}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public getDiscounts() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/discount/list/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  // pipeline
  public createPipeline(data) {
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/pipeline/create`, data).pipe(
      map((res) => res),
      tap(
        (res) => {
          this.snackBar.openSnackBar(res["message"]);
          this.getPipelines();
        },
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }

  // sources
  public getSources() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/source/list/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public addSource(data) {
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/source/create`, data).pipe(
      map((res) => res),
      tap(
        (res) => this.snackBar.openSnackBar(res["message"]),
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public deleteSource(sourceId) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/source/${sourceId}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public editSource(sourceId, data) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/source/${sourceId}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  //wishlist

  public getWishlists() {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/wishlistPreference/list/all`)
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public addWishlist(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/wishlistPreference/create`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public deleteWishlist(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/wishlistPreference/${id}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public editWishlist(id, data) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/wishlistPreference/${id}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // preferences
  public addPreferences(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/preferenceKey/create`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public editPreference(id, data) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/preferenceKey/${id}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public getPreferences() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/preferenceKey/list/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public deletePreferences(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/preferenceKey/${id}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public getPipelines() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/pipeline/list/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      // retry(3),
      catchError(this.response.handleError),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public editPipeline(id, data) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/pipeline/${id}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // Goals
  public createGoal(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/goal/create`, pickBy(data, identity))
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            this.router.navigate(["/settings/goals/manage-goal"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public getGoals() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/goal/list/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      retry(3),
      catchError(this.response.handleError),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public editGoal(id, data) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/goal/${id}/edit`, pickBy(data, identity))
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }
  public deleteGoal(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/goal/${id}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  // inventory
  public createInventory(data) {
    this.leadService.startLoading();
    return this.http
      .post(
        `${environment.API_URL}/inventory/item/create`,
        pickBy(data, identity)
      )
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            this.router.navigate(["/settings/inventory/manage-inventory"]);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public getInventory() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/inventory/item/list/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public editInventory(id, data) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/inventory/item/${id}/edit`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }
  public deleteInventory(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/inventory/item/${id}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  // today offer
  public todayOffer(params) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/discount/todays-discounts`, {
        params: params,
      })
      .pipe(
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // product
  public getallProducts(param?) {
    this.leadService.startLoading();
    let newParam = param ? param : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/product/getAllProducts`, {
        params: pickBy(newParam, identity),
      })
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // Email
  public userVerify(data): Observable<boolean> {
    this.leadService.startLoading();
    return this.http.patch(`${environment.API_URL}/team/joinTeam`, data).pipe(
      concatMap((duration) =>
        this.makeRequest(duration).pipe(map((res) => res))
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }
  makeRequest(timeToDelay): Observable<boolean> {
    return of(timeToDelay);
  }

  public checkProductDelete(id) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/product/${id}/checkDelete`)
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public addAttribute(id, data) {
    this.leadService.startLoading();
    return this.http
      .put(`${environment.API_URL}/product/${id}/add-attribute`, data)
      .pipe(
        concatMap((duration) =>
          this.makeRequest(duration).pipe(map((res) => res))
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public removeAttribute(id, data) {
    this.leadService.startLoading();
    return this.http
      .put(`${environment.API_URL}/product/${id}/remove-attribute`, data)
      .pipe(
        concatMap((duration) =>
          this.makeRequest(duration).pipe(map((res) => res))
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // Sender Verified

  public getSesEmailAddress() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/ses/email-addresses`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public addEmailAddresForVerification(data) {
    return this.http.post(`${environment.API_URL}/ses/verify-email`, data);
  }

  // Domain

  public getSesDomain() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/ses/domains`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public addDomain(data) {
    return this.http.post(`${environment.API_URL}/ses/verify-domain`, data);
  }
  public deleteDomain(id, dialog) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/ses/domain/${id}`)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  // Custom Fields Apies
  public getCustomFields() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/commonField`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public addCustomFields(data) {
    return this.http.post(`${environment.API_URL}/commonField/create`, data);
  }
  public editCustomFields(id, data) {
    return this.http.put(`${environment.API_URL}/commonField/${id}/edit`, data);
  }
  public deleteCustomFields(id) {
    return this.http.delete(`${environment.API_URL}/commonField/${id}/delete`);
  }

  public getTextSnippets() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/textSnippet`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public addTextnippets(data) {
    return this.http.post(`${environment.API_URL}/textSnippet/create`, data);
  }
  public editTextSnippets(id, data) {
    return this.http.put(`${environment.API_URL}/textSnippet/${id}/edit`, data);
  }
  public deleteTextSnippets(id) {
    return this.http.delete(`${environment.API_URL}/textSnippet/${id}/delete`);
  }

  public getCallLogs(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/exotel`, {
        params: pickBy(params, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res),
        finalize(() => this.leadService.stopLoading())
      );
  }
  public getSourceWiseLeads(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/lead/educationReport`, {
        params: pickBy(params, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res),
        finalize(() => this.leadService.stopLoading())
      );
  }
  public getLocationWiseLeads(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/lead/locationWiseReport`, {
        params: pickBy(params, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res),
        finalize(() => this.leadService.stopLoading())
      );
  }
  public getCourseWiseLeads(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/lead/courseWiseReport`, {
        params: pickBy(params, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res),
        finalize(() => this.leadService.stopLoading())
      );
  }
  public getCounselorWiseLeads(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/lead/salesPersonReport`, {
        params: pickBy(params, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res),
        finalize(() => this.leadService.stopLoading())
      );
  }
  public getCounselorWiseDeals(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/deal/salesPersonReport`, {
        params: pickBy(params, identity),
      })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res),
        finalize(() => this.leadService.stopLoading())
      );
  }
  public uploadFile(file) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/common/files`, file, {
        reportProgress: true,
        observe: "events",
      })
      .pipe(finalize(() => this.leadService.stopLoading()));
  }
  public getAttachmentLibrary() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/attachment-library`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public addAttachmentLibrary(data) {
    return this.http.post(
      `${environment.API_URL}/attachment-library/create`,
      data
    );
  }
  public editAttachmentLibrary(id, data) {
    return this.http.put(
      `${environment.API_URL}/attachment-library/${id}/edit`,
      data
    );
  }
  public deleteAttachmentLibrary(id) {
    return this.http.delete(
      `${environment.API_URL}/attachment-library/${id}/delete`
    );
  }
  public getReasonDealOrLeadClosed() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/lost-reason`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }
  public getReasonPieChart(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/deal/reasongraph`, { params: params })
      .pipe(
        shareReplay(),
        takeLast(1),
        map((res) => res),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public addReasonDealOrLeadClosed(data) {
    return this.http.post(`${environment.API_URL}/lost-reason/create`, data);
  }
  public editReasonDealOrLeadClosed(id, data) {
    return this.http.put(`${environment.API_URL}/lost-reason/${id}/edit`, data);        
  }
  public deleteReasonDealOrLeadClosed(id) {
    return this.http.delete(`${environment.API_URL}/lost-reason/${id}/delete`);
  }
  // Constant Crud

  public getConstant() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/constants/get/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public getMyOrders(params?) {
    this.leadService.startLoading();
    let newParam = params ? params : {};
    console.log(identity)
    return this.http.get(`${environment.API_URL}/user/order?token=w9nu9nom7kdy`,  {
      params: pickBy(newParam, identity),
    }).pipe(
      finalize(()=>{
        this.leadService.stopLoading()
      })
    );
  }
  
  public addConstant(data) {
    this.leadService.startLoading();
    
    return this.http.post(`${environment.API_URL}/constants/create`, data).pipe((
      finalize(() => {
        this.leadService.stopLoading()

      })
    ));
  }
  public editConstant(id, data) {
    return this.http.patch(
      `${environment.API_URL}/constants/update/${id}`,
      data
    );
  }
  public deleteConstant(id) {
    return this.http.delete(`${environment.API_URL}/constants/delete/${id}`);
  }

  public virtualTelephonyRequest(data) {
    return this.http.post(`${environment.API_URL}/user/telephony/request`, data);
  }

}
