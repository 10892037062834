import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "ageing",
})
export class AgeingPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let date = new Date();
    let diff = moment.utc(value).diff(moment.utc(date), "days"); // 1
    return diff;
  }
}
