import { Component, OnInit } from "@angular/core";
import { LeadService } from "./core/services/lead/lead.service";
import { debounceTime } from "rxjs/operators";
import { Subscription } from "rxjs";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  loading = false;
  loadingSubscription: Subscription;
  constructor(
    private leadService: LeadService,
  ) {
    // navigator.serviceWorker.getRegistrations().then(function (registrations) {
    //   for (let registration of registrations) {
    //     registration.unregister();
    //   }
    // });
  }

  ngOnInit() {
    this.loadingSubscription = this.leadService.loadingStatus
      .pipe(debounceTime(50))
      .subscribe((res) => {
        this.loading = res;
      });



  }

  
  
  ngAfterViewInit() {
    // Hack: Scrolls to top of Page after page view initialized
    let routerOutlet = document.getElementById("router-outlet");
    if (routerOutlet !== null) {
      routerOutlet.scrollIntoView();
      routerOutlet = null;
    }
  }

  ngOnDestroy() {
    this.loadingSubscription.unsubscribe();
  }
}
