import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
// import { CdkTableModule } from '@angular/cdk/table';
import { LayoutModule } from "@angular/cdk/layout";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import {
  MatAutocompleteModule,
  // MatButtonToggleModule,
  MatCardModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatIconModule,
  MatListModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  // MatDividerModule,
  // MatExpansionModule,
  // MatGridListModule,
  // MatIconModule,

  // MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  // MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  // MatRippleModule,
  MatSelectModule,
  // MatSidenavModule,
  // MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  // MatToolbarModule,
  MatTooltipModule,
} from "@angular/material";

import { MatInputModule } from "@angular/material/input";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";

import { RelativeTimePipe } from "../../pipes/relative-time/relative-time.pipe";
import { AgeingPipe } from "../../pipes/ageing/ageing.pipe";
import { PendingTimePipe } from "../../pipes/pending-time.pipe";
import { TaskSortPipe } from "../../pipes/task-sort/task-sort.pipe";
import { UniquePipe } from "../../pipes/unique-array/unique.pipe";
import { RoundPipe } from "../../pipes/round/round.pipe";
import { AccountComponent } from "src/app/views/dashboard/settings/view/account/account.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { UserEditComponent } from "src/app/views/dashboard/settings/view/account/user-edit/user-edit.component";
import { UserDeleteComponent } from "src/app/views/dashboard/settings/view/account/user-delete/user-delete.component";
import { Ng2SearchPipeModule } from "ng2-search-filter";
import { MatSelectInfiniteScrollModule } from "ng-mat-select-infinite-scroll";

@NgModule({
  imports: [
    LayoutModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    // MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,

    MatDialogModule,
    // MatDividerModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    MatInputModule,
    // MatListModule,
    MatMenuModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatPaginatorModule,
    // MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    // MatRippleModule,
    MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    Ng2SearchPipeModule,
    MatProgressBarModule,
    MatSelectInfiniteScrollModule,
    // MatToolbarModule,
    // MatTooltipModule
  ],
  declarations: [
    RelativeTimePipe,
    AgeingPipe,
    PendingTimePipe,
    TaskSortPipe,
    UniquePipe,
    RoundPipe,
    AccountComponent,
    UserEditComponent,
    UserDeleteComponent,
  ],
  exports: [
    LayoutModule,
    // CdkTableModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    // MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,

    MatDialogModule,
    Ng2SearchPipeModule,
    // MatDividerModule,
    // MatExpansionModule,
    // MatGridListModule,
    // MatIconModule,
    MatInputModule,
    // MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatPaginatorModule,
    // MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    // MatRippleModule,
    MatSelectModule,
    // MatSidenavModule,
    // MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    // MatToolbarModule,
    MatTooltipModule,
    RelativeTimePipe,
    AgeingPipe,
    PendingTimePipe,
    TaskSortPipe,
    UniquePipe,
    RoundPipe,
    AccountComponent,
    UserEditComponent,
    UserDeleteComponent,
    MatProgressBarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MaterialModule {}
