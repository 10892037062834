import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })

export class MarketingGuard implements CanActivate {

    constructor(
        private router: Router,
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // let organizationModule = JSON.parse(localStorage.getItem('redirection-modules'));
        let organizationModule;
        if (localStorage.getItem("redirection-modules")) {
            organizationModule = JSON.parse(
                localStorage.getItem("redirection-modules")
            );
        }
        else {
            organizationModule = JSON.parse(
                localStorage.getItem("organization-modules")
            );
        }
        let role = JSON.parse(localStorage.getItem('role'));
        if (localStorage.getItem('currentUser') && (organizationModule.indexOf('Marketing') != -1 || organizationModule.indexOf('marketing') != -1)) {
            return true;
        }
        else {
            this.router.navigate(['']);
            return false;
        }
    }
}