import { LEAD_COUNT } from "../actions/appActions";
import { isEqual } from "lodash";

export interface leadReducerState {
  leadCount: any;
}

const initialState: leadReducerState = {
  leadCount: 0,
};

export function leadReducer(state = initialState, action): leadReducerState {
  switch (action.type) {
    case LEAD_COUNT:
      return {
        ...state,
        leadCount: action.payload,
      };
  }
  return state;
}


