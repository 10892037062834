import {
  DATE_FILTER,
  DATE_FILTER_REMOVE,
  DATE_FILTER_SERVICE,
  DATE_FILTER_REMOVE_SERVICE,
  DATE_FILTER_MARKETING,
} from "../actions/appActions";

export interface appReducerState {
  showSidebar: boolean;
  dateFilterData: Object;
  dateFilterService: Object;
  dateFilterDataMarketing: Object;
  dateRemove: boolean;
}

const initialState: appReducerState = {
  showSidebar: false,
  dateFilterData: {},
  dateFilterDataMarketing: {},
  dateFilterService: {},
  dateRemove: false,
};

export function appReducer(state = initialState, action): appReducerState {
  switch (action.type) {
    case DATE_FILTER:
      return {
        ...state,
        dateFilterData: action.payload,
      };

    case DATE_FILTER_REMOVE:
      return {
        ...state,
        dateRemove: true,
      };
    case DATE_FILTER_SERVICE:
      return {
        ...state,
        dateFilterService: action.payload,
      };

    case DATE_FILTER_REMOVE_SERVICE:
      return {
        ...state,
        dateFilterService: {},
      };
    case DATE_FILTER_MARKETING:
      return {
        ...state,
        dateFilterDataMarketing: action.payload,
      };
  }
  return state;
}
