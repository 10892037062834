import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  HttpInterceptorService,
  HttpErrorInterceptorService,
} from "./core/utils/httpInterceptor/http-interceptor.service";
import { DateRangePickerModule } from "@syncfusion/ej2-angular-calendars";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MaterialModule } from "./core/module/material/material.module";
import { StoreModule } from "@ngrx/store";
import { reducers } from "./core/store/reducers";

import { ThankYouforResponseComponent } from "./views/unsubscribe/thank-youfor-response/thank-youfor-response.component";



import { BuyCreditFormComponent } from './views/buy-credit-form/buy-credit-form.component';

import { GetstarteddialogComponent } from './getstarteddialog/getstarteddialog.component';
import { MatBadgeModule } from "@angular/material";

//import localeFr from '@angular/common/locales/fr';
import { ForgotPasswordModalComponent } from "./views/auth/forgot-password-modal/forgot-password-modal.component";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { canDeactivateforWorkflow } from "./core/guard/candeactivate/candeactvate.guard";
import { environment } from "src/environments/environment";
//registerLocaleData(localeFr);
let apiurl = environment.API_URL
apiurl = apiurl.split('/api')[0]
// const config: SocketIoConfig = { url: 'https://b2b.qnvert.com', options: {} };
const config: SocketIoConfig = { url: apiurl, options: {} };

const entryComponents = [
  ThankYouforResponseComponent,
  BuyCreditFormComponent
]


@NgModule({
  declarations: [
    AppComponent,
    GetstarteddialogComponent,
    ForgotPasswordModalComponent,
    ...entryComponents,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    DateRangePickerModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    AppRoutingModule,
    SocketIoModule.forRoot(config),

    StoreModule.forRoot(reducers, {}),
    FormsModule,
    ReactiveFormsModule,
    MatBadgeModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true,
    },
   canDeactivateforWorkflow,


    // { provide: LOCALE_ID, useValue: 'fr' }
  ],
  entryComponents: [
     ...entryComponents,
     ForgotPasswordModalComponent
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
