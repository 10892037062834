import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AuthService } from '../core/services/auth/auth.service';

@Component({
  selector: 'app-getstarteddialog',
  templateUrl: './getstarteddialog.component.html',
  styleUrls: ['./getstarteddialog.component.scss']
})
export class GetstarteddialogComponent implements OnInit {
  list: any = [];
  details: any;
  totalRecords: number = 0;
  type!: string;
  page: any = {
    page: 0,
    limit: 10,
  };

  check: boolean

  constructor(
    public dialogRef: MatDialogRef<GetstarteddialogComponent>,
    // @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.getData();
    this.getTaskList();

  }

  getTaskList() {
    this.auth.getStartedList.subscribe((res: any) => {
      if (res) {
        this.list = res.data.list;
        this.details = res.data.details

      }
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  getData() {
    let type = "Sales";
    this.auth.getAllStartedList(type);
  }

  checkList(e: any, item) {
    console.log(e);
    this.auth.markCompleted(item._id)
    this.getTaskList();
    setTimeout(() => {
      this.getData();
  }, 100);
  }
}
