import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { SnackbarService } from 'src/app/core/utils/snackbar/snackbar.service';

export interface DialogData {
  data
}


@Component({
  selector: 'app-user-delete',
  templateUrl: './user-delete.component.html',
  styleUrls: ['./user-delete.component.scss']
})
export class UserDeleteComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService:UserService,
    private snackBar:SnackbarService,
    private dialogRef: MatDialogRef<UserDeleteComponent>,
  ) { }

  ngOnInit() {
    console.log(this.data)
  }

  delete(){
    this.userService.deleteUserComplete(this.data,this.dialogRef)
  }

}
