import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./core/guard/authguard/auth.gaurd";
import { MarketingGuard } from "./core/guard/marketing/marketing.guard";

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "marketing",
    loadChildren: () => import('./views/marketing/marketing.module').then(m => m.MarketingModule),
    canActivate: [MarketingGuard],
  },
  
  {
    path: "unsubscribe/:token",
    loadChildren: () => import('./views/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule),
  },
  {
    path: "login",
    loadChildren: () => import('./views/auth/login/login.module').then(m => m.loginModule)
  },
  {
    path: "signup",
    loadChildren: () => import('./views/auth/signup/signup.module').then(m => m.signUpModule)
  },
  {
    path: "account-created",
    loadChildren: () => import('./views/auth/account-created/account-created.module').then(m => m.accountCreatedModule)
  },
  {
    path: "check-domain",
    loadChildren: () => import('./views/auth/check-domain/checkDomain.module').then(m => m.checkDomainModule)
  },
  {
    path: "create-domain",
    loadChildren: () => import('./views/auth/check-domain/checkDomain.module').then(m => m.checkDomainModule)
  },
  {
    path: "verify-your-email",
    loadChildren: () => import('./views/auth/email-verified/emailVerified.module').then(m => m.emailVerifiedModule)
  },
  {
    path: "verify-email",
    loadChildren: () => import('./views/auth/verify-email/verify-email.module').then(m => m.verifyEmailModule)
  },

  {
    path: "thank-you",
    loadChildren: () => import('./views/thank-you/thankYou.module').then(m => m.ThankYouModule)
  },
  {
    path: "not-found",
    loadChildren: () => import('./views/not-found/not-found.module').then(m => m.NotFoundModule)
  },
  {
    path: "forgot-password",
    loadChildren: () => import('./views/auth/forgot-password/forgot-password.module').then(m => m.forgotPasswordModule)
  },

  {
    path: "",
    redirectTo: "",
    pathMatch: "full",
  },
  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
