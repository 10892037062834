import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';

import { AuthService } from '../../../../../core/services/auth/auth.service';
import { SnackbarService } from '../../../../../core/utils/snackbar/snackbar.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

export function patternValidator(regexp: RegExp): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    const value = control.value;
    if (value === '') {
      return null;
    }
    return !regexp.test(value) ? { 'patternInvalid': { regexp } } : null;
  };
}
export interface DialogData {
}

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  organization = localStorage.getItem('organization');
  @ViewChild('resetForm') resetForm;  
  allRoles;
  signupForm;
  alluserGroups;
  constructor(
    private fb:FormBuilder,
    private auth: AuthService,
    private snackBar: SnackbarService,
    private dialogRef:MatDialogRef<AccountComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit() {
    this.createForm();
    if(this.data == 'sales'){
      this.getRoles({modules:'sales'});
    }
    else{
      this.getRoles({modules:'marketing'});

    }
  }

  createForm(): void {
    this.signupForm = this.fb.group({
      name: ['',Validators.required],
      email: ['', [Validators.required, patternValidator(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['',Validators.required],
      phone: ['',Validators.required],
      // group:['',Validators.required],
      role:['',Validators.required],

    });
  }

  signup(data){
    this.auth.signup(data).
    subscribe(
      (res)=>{
        this.snackBar.openSnackBar(res['message']);
        // this.resetForm.resetForm();
        this.dialogRef.close(true);
      },
      (err)=>{
        this.snackBar.openSnackBar(err);
      }
    )
  }

  getRoles(params){
    this.auth.getRoles(params).subscribe(
      (res) =>{
        console.log(res)
        this.allRoles = res['data'];
      }
    )
  }

}
