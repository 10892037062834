import { appReducer,appReducerState } from './appReducers';
import { ActionReducerMap } from '@ngrx/store';
import { leadReducerState, leadReducer } from './leadReducers';

interface AppState{
    appReducers:appReducerState,
    leadReducers:leadReducerState
}

export const reducers:ActionReducerMap<AppState> = {
    appReducers:appReducer,
    leadReducers:leadReducer
}
