import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "taskSort",
})
export class TaskSortPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      let returnData = [];
      returnData = value.filter((element) => {
        if (element.lead || (element.deal && element.deal.lead)) {
          return element.lead || element.deal.lead;
        }
      });
      return returnData;
    }
    
  }
}
