import { Pipe, PipeTransform } from "@angular/core";
import * as lodash from "lodash";

@Pipe({
  name: "unique",
})
export class UniquePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    value = lodash.uniqBy(value, function (e) {
      return e.user._id;
    });
    return value;
  }
}
