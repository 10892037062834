import { Injectable } from "@angular/core";
import { environment } from "../../../../environments/environment";
import { pickBy, identity } from "lodash";
import { ResponseService } from "../../utils/response/response.service";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map, finalize, shareReplay, tap } from "rxjs/operators";
import { SnackbarService } from "../../utils/snackbar/snackbar.service";
import { LoaderService } from "../../utils/loader/loader.service";
const EXCEL_TYPE =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const EXCEL_EXTENSION = ".xlsx";
import { saveAs } from "file-saver";
import { BehaviorSubject } from "rxjs";
import { LeadService } from "../lead/lead.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  session;
  public profileData$ = new BehaviorSubject<any>("");

  constructor(
    private http: HttpClient,
    private response: ResponseService,
    private loader: LoaderService,
    private snackBar: SnackbarService,
    private leadService: LeadService
  ) { }

  signupURL= "https://signup.qnvert.com";
  // signupURL= "http://656a01246a9c.ngrok.io";

  public getManagers() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/team/all-managers/list`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public createTeam(data) {
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/team/create`, data).pipe(
      map((res) => res),
      tap(
        (res) => this.snackBar.openSnackBar(res["message"]),
        (err) => this.snackBar.openSnackBar(err)
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public createOrder(data) {
    this.leadService.startLoading();
    return this.http.post(`${this.signupURL}/api/order`, data).pipe(map((res) => res), finalize(() => this.leadService.stopLoading()));
  }

  public verifyOrder(data, id) {
    this.leadService.startLoading();
    return this.http.post(`${this.signupURL}/api/order/${id}`, data).pipe(map((res) => res), finalize(() => this.leadService.stopLoading()));
  }

  public updateCredits(data, id) {
    this.leadService.startLoading();
    return this.http.post(`${this.signupURL}/api/order/update-credits/${id}`, data).pipe(map((res) => res), finalize(() => this.leadService.stopLoading()));
  }

  public getPromoCodeData(data){
    return this.http.get(`${this.signupURL}/api/promo/${data}`); 
  }

  public getAllSubscriptions() {
    this.leadService.startLoading();
    return this.http.get(`${this.signupURL}/api/plan`).pipe(map((res) => res), finalize(() => this.leadService.stopLoading()));
  }

  public getSubscriptions(id) {
    this.leadService.startLoading();
    return this.http.get(`${this.signupURL}/api/plan/${id}`).pipe(map((res) => res), finalize(() => this.leadService.stopLoading()));
  }

  public subscriptionOrder(obj) {
    this.leadService.startLoading();
    return this.http.post(`${this.signupURL}/api/subscription`, obj).pipe(map((res) => res), finalize(() => this.leadService.stopLoading()));
  }

  public verifySubscription(data, id) {
    this.leadService.startLoading();
    return this.http.post(`${this.signupURL}/api/subscription/${id}`, data).pipe(map((res) => res), finalize(() => this.leadService.stopLoading()));
  }

  
  public getUserSubscriptions() {
    this.leadService.startLoading();
    return this.http.get(`${this.signupURL}/api/subscription`).pipe(map((res) => res), finalize(() => this.leadService.stopLoading()));
  }


  public inviteUser(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/team/sendInvite`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public getTeams() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/team/list/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public getusers(id) {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/team/${id}/details`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public deleteUser(id, data) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/team/${id}/remove-member`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  // dashboard chart
  public getHeatMap(param?) {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam = param ? param : {};
    newParam.session = this.session;
    newParam.isVerified = "Verified and interested";
    return this.http
      .get(`${environment.API_URL}/deal/heatMap`, { params: newParam })
      .pipe(
        shareReplay(),
        map((res) => res),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public getGoalsData(params?) {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    return this.http
      .get(`${environment.API_URL}/goal/dashboard`, { params: params })
      .pipe(
        shareReplay(),
        map((res) => res["data"]),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public getLocationData(params?) {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam = params ? params : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/lead/dashboard`, { params: newParam })
      .pipe(
        shareReplay(),
        map((res) => res["data"]),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public getProductvsdeals(params?) {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam = params ? params : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/lead/productVsDealsChart`, {
        params: newParam,
      })
      .pipe(
        shareReplay(),
        map((res) => res["data"]),
        finalize(() => this.leadService.stopLoading())
      );

  }

  public wishlistvsReports(params?) {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam = params ? params : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/lead/getWishlistReport`, {
        params: newParam,
      })
      .pipe(
        shareReplay(),
        map((res) => res["data"]),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public contactVsSources(params?) {
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam = params ? params : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/lead/sourceWiseLeads`, { params: newParam })
      .pipe(
        shareReplay(),
        map((res) => res["data"])
      );
  }

  public getProductvswondeals(params?) {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam = params ? params : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/deal/productVsWonDeals`, {
        params: newParam,
      })
      .pipe(
        shareReplay(),
        map((res) => res["data"]),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public locationVsRevenue(params?) {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam = params ? params : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/lead/locationVsRevenue`, {
        params: newParam,
      })
      .pipe(
        shareReplay(),
        map((res) => res["data"]),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public salesVsDeal(params?) {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam = params ? params : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/lead/salesVsDealsChart`, {
        params: newParam,
      })
      .pipe(
        map((res) => res["data"]),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public salesVsRevenue(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/deal/salesVsRevenue`, { params: params })
      .pipe(
        map((res) => res["data"]),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public salesVsConversion(params?) {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam = params ? params : {};
    newParam.session = this.session;
    return this.http
      .get(`${environment.API_URL}/deal/salesVsConversion`, {
        params: newParam,
      })
      .pipe(
        map((res) => res["data"]),
        finalize(() => this.leadService.stopLoading())
      );
  }

  // message

  public createMessage(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/msg/create`, pickBy(data, identity))
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public getMessages() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/msg/list/all`).pipe(
      shareReplay(),
      map((res) => res),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public deleteMessage(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/msg/${id}/delete`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public addAgent(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/commission-agent/create`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public editAgent(id, data) {
    this.leadService.startLoading();
    return this.http
      .patch(
        `${environment.API_URL}/commission-agent/update/${id}`,
        pickBy(data, identity)
      )
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public deleteAgent(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/commission-agent/delete/${id}`)
      .pipe(
        map((res) => res),
        tap(
          (res) => this.snackBar.openSnackBar(res["message"]),
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public getAgets(data) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/commission-agent/get/all`, { params: data })
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public agentProfile(id) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/commission-agent/get/${id}`)
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      );
  }

  public getTemplates(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/template`, {
        params: pickBy(params, identity),
      })
      .pipe(
        shareReplay(),
        finalize(() => this.leadService.stopLoading())
        // map(this.response.extractData),
      );
  }
  public getSingleTemplates(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/template/${params}`)
      .pipe(
        shareReplay(),
        finalize(() => this.leadService.stopLoading())
        // map(this.response.extractData),
      );
  }




  public getAllUsers(param?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/user/list`, { params: param })
      .pipe(
        shareReplay(),
        finalize(() => this.leadService.stopLoading())
        // map(this.response.extractData),
      );
  }

  public exportProductvsdeals() {
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam: any = {
      exports: true,
      session: this.session,
    };
    this.leadService.startLoading();
    this.http
      .get(`${environment.API_URL}/lead/productVsDealsChart`, {
        params: newParam,
        responseType: "text",
      })
      .pipe(finalize(() => this.leadService.stopLoading()))
      .subscribe(
        (res) => {
          this.downloadFile(res);
        },
        (err) => { }
      );
  }

  public exportContactvsSource() {
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam: any = {
      exports: true,
      session: this.session,
    };

    this.http
      .get(`${environment.API_URL}/lead/sourceWiseLeads`, {
        params: newParam,
        responseType: "text",
      })
      .subscribe(
        (res) => {
          this.downloadFile(res);
        },
        (err) => { }
      );
  }

  public exportSalesVsDeal() {
    this.session = JSON.parse(localStorage.getItem("session"));
    this.leadService.startLoading();
    let newParam: any = {
      exports: true,
      session: this.session,
    };
    this.http
      .get(`${environment.API_URL}/lead/salesVsDealsChart`, {
        params: newParam,
        responseType: "text",
      })
      .pipe(finalize(() => this.leadService.stopLoading()))
      .subscribe((res) => {
        this.downloadFile(res);
      });
  }

  public exportSalesVsRevenue() {
    this.leadService.startLoading();
    this.http
      .get(`${environment.API_URL}/deal/salesVsRevenue`, {
        params: { exports: "true" },
        responseType: "text",
      })
      .pipe(finalize(() => this.leadService.stopLoading()))
      .subscribe((res) => {
        this.downloadFile(res);
      });
  }

  public exportHeatMap() {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam: any = {
      exports: true,
      session: this.session,
      isVerified: "Verified and interested",
    };
    this.http
      .get(`${environment.API_URL}/deal/heatMap`, {
        params: newParam,
        responseType: "text",
      })
      .pipe(finalize(() => this.leadService.stopLoading()))
      .subscribe((res) => {
        this.downloadFile(res);
      });
  }
  public exportCallLogs() {
    this.leadService.startLoading();
    this.session = JSON.parse(localStorage.getItem("session"));
    let newParam: any = {
      exports: true,
      session: this.session,
    };
    this.http
      .get(`${environment.API_URL}/exotel`, {
        params: newParam,
        responseType: "text",
      })
      .pipe(finalize(() => this.leadService.stopLoading()))
      .subscribe((res) => {
        this.downloadFile(res);
      });
  }

  downloadFile(data) {
    let blob = new Blob([data], { type: "text/csv" });
    
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, "filename.csv");
    } else {
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "filename.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  public editUser(id, data, dialog) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/user/edit/${id}`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public deleteUserComplete(id, dialog) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/user/${id}`)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public getSession() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/session/allSession`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public addSession(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/session`, data)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }

  public editProfile(data, dialog) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/user/editProfile`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar(res["message"]);
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public getProfile() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/user/profile`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public getDashProfile() {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/user/profile`)
      .pipe(
        shareReplay(),
        map(this.response.extractData),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe((res) => {
        this.profileData$.next(res);
      });
  }

  public contactQnvert(data, dialog) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/contact-form`, data)
      .pipe(
        map((res) => res),
        tap(
          (res) => {
            this.snackBar.openSnackBar("Your message is sent successfully");
            dialog.close(true);
          },
          (err) => this.snackBar.openSnackBar(err)
        ),
        finalize(() => this.leadService.stopLoading())
      )
      .subscribe();
  }

  public getAllMessages() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/contact-form/list`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public getLeads(params) {
    let session = JSON.parse(localStorage.getItem("session"));
    let newParam = params ? params : {};
    newParam.session = session;
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/lead/getleads`, {
        params: pickBy(newParam, identity),
        responseType: "arraybuffer",
      })
      .pipe(finalize(() => this.leadService.stopLoading()))
      .subscribe((res) => {
        this.saveAsExcelFile(res, "leads");
      });
  }

  public getProjectConstant() {
    this.leadService.startLoading();
    return this.http.get(`${environment.API_URL}/constants/get/all`).pipe(
      shareReplay(),
      map(this.response.extractData),
      finalize(() => this.leadService.stopLoading())
    );
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
  }

  // public facebook() {
  //   return this.http.get(`${environment.API_URL}/service/fb/auth`,{responseType: 'text'})
  // }

  // public google() {
  //   return this.http.get(`${environment.API_URL}/service/google/auth`,{responseType: 'text'})
  // }

  // public twitter() {
  //   return this.http.get(`${environment.API_URL}/service/twitter/auth`,{responseType: 'text'})
  // }

  // public outlook() {
  //   return this.http.get(`${environment.API_URL}/service/outlook/auth`,{responseType: 'text'})
  // }

  public deleteTeam(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/team/${id}`)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }

  public deletePipeline(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/pipeline/${id}/delete`)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }

  public editTeam(id, data) {
    this.leadService.startLoading();
    return this.http
      .patch(`${environment.API_URL}/team/${id}/update`, data)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }

  public connectCt(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/ct/connect`, data)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }
  public pdfForCt(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/ct/pdf`, data)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }
  public getFilesCt() {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/ct/getFiles`)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }

  public getSingleFilesCt(params) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/ct/session`, { params: params })
      .pipe(finalize(() => this.leadService.stopLoading()));
  }
  public getSingleFilesCtAccordingDate(id, fromDate, toDate) {
    let params = new HttpParams().set("from", fromDate).set("to", toDate);
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/ct/getFiles/${id}`, { params: params })
      .pipe(finalize(() => this.leadService.stopLoading()));
  }

  public deleteFile(id) {
    this.leadService.startLoading();
    return this.http
      .delete(`${environment.API_URL}/ct/delete/${id}`)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }
  public viewReportAccordingToLead(leadId) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/campaigns/activity/excel/${leadId}`, {
        responseType: "blob",
      })
      .pipe(finalize(() => this.leadService.stopLoading()))
      .subscribe((res) => {
        this.downloadViewReportFile(res);
      });
  }
  downloadViewReportFile(data) {
    let blob = new Blob([data], { type: "text/xlsx" });
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveBlob(blob, "campaign-activity.xlsx");
    } else {
      let a = window.document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "campaign-activity.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
  public downloadTaskData() {
    // let session = JSON.parse(localStorage.getItem("session"));
    // let newParam = params ? params : {};
    // newParam.session = session;
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/pendingTask/pendingTaskData`, {
        responseType: "arraybuffer",
      })
      .pipe(finalize(() => this.leadService.stopLoading()))
      .subscribe((res) => {
        this.saveAsExcelFile(res, "tasks");
      });
  }

  public askForDemo(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/user/demo/request`, data)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }

  
  public FAQ() {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/user/help`)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }
}
