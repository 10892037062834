import { Pipe, PipeTransform } from '@angular/core';
import * as distanceInWordsToNow from 'date-fns/distance_in_words_to_now';


@Pipe({
  name: 'pendingTime'
})
export class PendingTimePipe implements PipeTransform {

  transform(value: string, ...args) {
    let time = distanceInWordsToNow(new Date(value), { addSuffix: true });
    var index = time.indexOf("ago");
    if(index >0){
      return 'Pending since ' + distanceInWordsToNow(new Date(value), { addSuffix: true });
    }
    else{
      return 'Upcoming ' + distanceInWordsToNow(new Date(value), { addSuffix: true });
    }
  }

}
