import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { concatMap, tap, map, shareReplay, finalize } from "rxjs/operators";
import { BehaviorSubject, Observable, of } from "rxjs";
import { environment } from "./../../../../environments/environment";
// import { ResponseService } from '../../utils/response/response.service';
import { StorageService } from "../../utils/storage/storage.service";
import { SnackbarService } from "../../utils/snackbar/snackbar.service";
import { LoaderService } from "../../utils/loader/loader.service";
import { LeadService } from "../lead/lead.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  session = JSON.parse(localStorage.getItem("session"));
  constructor(
    private http: HttpClient,
    // private response: ResponseService,
    private storage: StorageService,
    private snackBar: SnackbarService,
    private loader: LoaderService,
    private leadService: LeadService
  ) { }

  getStartedList: BehaviorSubject<any> = new BehaviorSubject<any>('');

  getAllStartedList(type?:any){
    console.log("type", type);
    let url= `${environment.API_URL}/getStarted`;
    if(type){
      url = `${environment.API_URL}/getStarted?type=${type}`
    }
    this.http.get(url).subscribe((res)=>{
      this.getStartedList.next(res);
    })
  }

  getAllStartedListNew(type?:any){
    console.log("type", type);
    let url= `${environment.API_URL}/getStarted`;
    if(type){
      url = `${environment.API_URL}/getStarted?type=${type}`
    }
    return this.http.get(url);
  }

  public login(data): Observable<boolean> {
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/user/login`, data).pipe(
      // shareReplay(),
      concatMap((duration) =>
        this.makeRequest(duration).pipe(
          tap(
            (res:any) => {
          sessionStorage.setItem("userId",res.data.id)
     

              console.log(res, 'login data')
              
              this.storage.token = res["data"];
            },
            (err) => { }
          )
        )
      ),
      finalize(() => this.leadService.stopLoading())
    );
  }

  public signup(data): Observable<boolean> {
    let session = JSON.parse(localStorage.getItem("session"));
    data.session = session;
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/user/create`, data).pipe(
      shareReplay(),
      concatMap((duration) =>
        this.makeRequest(duration).pipe(
          map((res) => res),
          tap(
            (res) => {
              this.snackBar.openSnackBar(res["message"]);
            },
            (err) => this.snackBar.openSnackBar(err)
          ),
          finalize(() => this.leadService.stopLoading())
        )
      )
    );
  }



  public getRoles(params?) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/role/get/all`, { params: params })
      .pipe(
        shareReplay(),
        finalize(() => this.leadService.stopLoading())
        // map(this.response.extractData),
      );
  }

  makeRequest(timeToDelay): Observable<boolean> {
    return of(timeToDelay);
  }

  forgotPass(data) {
    console.log("forgot data",data)
    this.leadService.startLoading();
    return this.http.post(`${environment.API_URL}/user/forgot-password`,data)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }

  verifyOtp(data){
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/user/verifyOtp`, data)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }


  resetPassword(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/user/reset-password`, data)
      .pipe(finalize(() => this.leadService.stopLoading()));
  }


  createDomain(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/user/signup`, data)
      .pipe(
        shareReplay(),
        finalize(() => this.leadService.stopLoading())
        // map(this.response.extractData),
      );
  }

  checkDomain(data) {
    this.leadService.startLoading();
    return this.http
      .get(`${environment.API_URL}/user/check-subdomain/${data.domain}`)
      .pipe(
        shareReplay(),
        finalize(() => this.leadService.stopLoading())
        // map(this.response.extractData),
      );
  }

  companySignup(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/tempuser/signup`, data)
      .pipe(
        shareReplay(),
        finalize(() => this.leadService.stopLoading())
        // map(this.response.extractData),
      );
  }

  verifyEmail(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/tempuser/verifyEmail`, data)
      .pipe(
        shareReplay(),
        finalize(() => this.leadService.stopLoading())
      );
  }

  resendEmail(data) {
    this.leadService.startLoading();
    return this.http
      .post(`${environment.API_URL}/tempuser/resendVerifyEmail`, data)
      .pipe(
        shareReplay(),
        finalize(() => this.leadService.stopLoading())
      );
  }
  markCompleted(id){
    return this.http.put(`${environment.API_URL}/getStarted/${id}`,{}).subscribe((data:any)=>{
      console.log(data)
    })
  }
}
